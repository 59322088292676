<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        :color="state ? 'success' : 'error'"
        outlined
        x-small
        v-on="on"
        @click="changeState()"
      >
        {{ state ? 'Enabled' : 'Disabled' }}
      </v-btn>
    </template>
    Click to {{ state ? 'disable' : 'enable' }}
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { MachinesApiService } from '../_services/machines.api.service';

export default {
  name: 'DsbMachineState',

  props: {
    name: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },

  methods: {
    changeState({ state = !this.state, name = this.name } = {}) {
      this.$VBlackerTheme.alert.warning({
        width: `${name.length * 15 + 300}px`,
        text: `${state ? 'Enable' : 'Disable'} "${name}"?`,
      }, () => this.wrapToLoadingFn({
        req: MachinesApiService.changeMachineState.bind({}, {
          suffix: this.suffix,
          state,
          name,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully changed');
          this.$emit('state:change:success');
        },
      }));
    },

    wrapToLoadingFn,
  },
};
</script>
