<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <template #toolbar>
        <dsb-machines-sync
          v-if="hadAdminRole"
          @sync:success="$_refreshData"
        />
      </template>

      <vbt-table
        :headers="machinesHeaders"
        :items="machines"
      >
        <template #state="{ item }">
          <dsb-machine-state
            v-if="hadAdminRole"
            :name="item.name"
            :state="item.enabled"
            @state:change:success="$_fetchData"
          />

          <dsb-entity-badge
            v-else
            :color="item.enabled ? 'success' : 'error'"
          >
            {{ item.enabled ? 'Enabled' : 'Disabled' }}
          </dsb-entity-badge>
        </template>

        <template #actions="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="info"
                v-on="on"
                @click="showMachineHistory(item.name)"
              >
                <v-icon>
                  book
                </v-icon>
              </v-btn>
            </template>
            Show history
          </v-tooltip>
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
        :total-count="machinesTotalCount"
      />

      <vbt-dialog
        v-model="machinesHistoryDialog"
        :title="machinesHistoryDialogTitle"
        hide-submit-btn
      >
        <vbt-table
          :headers="machineHistoryHeaders"
          :items="machineHistory"
        >
          <template #processed="{ item: { processed: _dateForFormatting } }">
            {{ formatDisplayDate(_dateForFormatting) }}
          </template>

          <template #state="{ item }">
            <dsb-entity-badge :entity="item.targetState ? 'Enabled' : 'Disabled'" />
          </template>
        </vbt-table>
      </vbt-dialog>
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import {
  AuthNamespace,
  AutocompleteNamespace,
  AutocompleteActionTypes,
  AppLocationsNamespace,
} from '@store/types';

import {
  namespace as MachinesNamespace,
  ActionTypes as MachinesActionTypes,
} from '../store.types';

import DsbMachinesSync from '../_components/DsbMachinesSync.vue';
import DsbMachineState from '../_components/DsbMachineState.vue';

const dataLoadingOptions = {
  getterName: MachinesActionTypes.GET_MACHINES,
  contentName: 'machines',
  specialQueryParams: [
    {
      name: 'state',
      type: 'custom',
      parser: (value) => {
        const types = { enabled: true, disabled: false };

        return value ? types[value.toLowerCase()] : null;
      },
    },
  ],
};
const paginationOptions = { contentName: 'machines' };
const searchOptions = {
  initialSearchDataFields: [
    'key', 'name', 'state',
  ],
};

const machinesHeaders = Object.freeze([
  { text: 'Public Key', value: 'publicKey' },
  { text: 'Name', value: 'name' },
  { text: 'State', value: 'state' },
  { text: '', value: 'actions', width: '50px' },
]);

const machineHistoryHeaders = Object.freeze([
  { text: 'Operator', value: 'operator' },
  { text: 'Processed Date', value: 'processed' },
  { text: 'State', value: 'state' },
]);

export default {
  name: 'DsbMachines',

  components: {
    DsbMachinesSync,
    DsbMachineState,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      machinesHistoryDialog: false,
      machinesHistoryDialogTitle: '',

      machinesHeaders,
      machineHistoryHeaders,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
    ...mapState(AutocompleteNamespace, ['machineNameAutocomplete', 'machinePublicKeyAutocomplete']),

    ...mapState(MachinesNamespace, ['machines', 'machinesTotalCount', 'machineHistory']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    searchFieldsConfig() {
      return [
        {
          value: 'key',
          label: 'Public Key',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.machinePublicKeyAutocomplete,
          callback: this.getMachinePublicKeyAutocomplete,
          flex: 'sm4',
        },
        {
          value: 'name',
          label: 'Name',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.machineNameAutocomplete,
          callback: this.getMachineNameAutocomplete,
          flex: 'sm4',
        },
        {
          value: 'state',
          label: 'State',
          items: ['Enabled', 'Disabled'],
          type: 'select',
          flex: 'sm4',
        },
      ];
    },
  },

  methods: {
    ...mapActions(AutocompleteNamespace, {
      getMachineNameAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_MACHINE_NAME_AUTOCOMPLETE,
        { query },
      ),
      getMachinePublicKeyAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE,
        { query },
      ),
    }),

    ...mapActions(MachinesNamespace, [MachinesActionTypes.GET_MACHINES]),
    ...mapActions(MachinesNamespace, {
      showMachineHistory(dispatch, name) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, MachinesActionTypes.GET_MACHINE_HISTORY, {
            suffix: this.suffix,
            name,
          }),
          fullscreenLoadingSpinner: true,
          onSuccess: () => {
            if (this.machineHistory.length) {
              this.machinesHistoryDialogTitle = `"${name}" history`;
              this.machinesHistoryDialog = true;
            } else {
              this.$VBlackerTheme.notification.warning('Empty history');
            }
          },
        });
      },
    }),

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
