var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.hadAdminRole)?_c('dsb-machines-sync',{on:{"sync:success":_vm.$_refreshData}}):_vm._e()]},proxy:true}])},[_c('vbt-table',{attrs:{"headers":_vm.machinesHeaders,"items":_vm.machines},scopedSlots:_vm._u([{key:"state",fn:function(ref){
var item = ref.item;
return [(_vm.hadAdminRole)?_c('dsb-machine-state',{attrs:{"name":item.name,"state":item.enabled},on:{"state:change:success":_vm.$_fetchData}}):_c('dsb-entity-badge',{attrs:{"color":item.enabled ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.enabled ? 'Enabled' : 'Disabled')+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.showMachineHistory(item.name)}}},on),[_c('v-icon',[_vm._v(" book ")])],1)]}}],null,true)},[_vm._v(" Show history ")])]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount,"total-count":_vm.machinesTotalCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}}),_c('vbt-dialog',{attrs:{"title":_vm.machinesHistoryDialogTitle,"hide-submit-btn":""},model:{value:(_vm.machinesHistoryDialog),callback:function ($$v) {_vm.machinesHistoryDialog=$$v},expression:"machinesHistoryDialog"}},[_c('vbt-table',{attrs:{"headers":_vm.machineHistoryHeaders,"items":_vm.machineHistory},scopedSlots:_vm._u([{key:"processed",fn:function(ref){
var _dateForFormatting = ref.item.processed;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"state",fn:function(ref){
var item = ref.item;
return [_c('dsb-entity-badge',{attrs:{"entity":item.targetState ? 'Enabled' : 'Disabled'}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }